.app {
  position: relative;
  overflow: hidden;
  background: #050606;
  min-width: 35em;
  min-height: 65em;
  font-size: 10px;
  .win-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .win-modal-open {
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(10px);
    z-index: 1000;
  }
  .win-modal-text {
    padding: 6em 3.3em;
    width: 31.5em;
    height: 33em;
    background: #1E2226;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    &__congrats {
      width: 32px;
      margin-left: 5px;
    }
    &__text {
      font-size: 32px;
      font-weight: 700;
      text-transform: uppercase;
      color: white;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .win__item {
        font-size: 20px;
      }
    }
  .app-coins {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .fall {
    animation: coinFall 3s linear infinite;
  }
  .app-coins-fall {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    animation: coinFall 2s linear infinite;
  }
    .app-coin1 {
      position: absolute;
      right: 26.9em;
      top: 38.6em;
      width: 14em;
      height: 12em;
      z-index: 1;
      animation: coin4anim 1.8s linear infinite alternate;
    }

    .app-coin2 {
      position: absolute;
      left: 33%;
      top: 32em;
      width: 10em;
      height: 10em;
      z-index: 1;
      animation: coin4anim 1.8s linear infinite alternate;
    }

    .app-coin2-2 {
      position: absolute;
      right: -3em;
      top: 13.2em;
      width: 10em;
      height: 10em;
      z-index: 1;
      animation: coin4anim 2s linear infinite alternate;
    }

    .app-coin3 {
      position: absolute;
      left: 7.7em;
      top: 61.6em;
      width: 5em;
      height: 4em;
      z-index: 1;
      animation: coin4anim 3s linear infinite alternate;
    }

    .app-coin3-2 {
      position: absolute;
      right: 9.6em;
      top: 61.6em;
      width: 5em;
      height: 4em;
      z-index: 1;
      animation: coin4anim 1.5s linear infinite alternate;
    }

    .app-coin4 {
      position: absolute;
      left: 53.8em;
      top: 7em;
      width: 13em;
      height: 12em;
      z-index: 1;
    }

    .app-coin4-2 {
      position: absolute;
      right: 42.2em;
      top: 25.5em;
      width: 13em;
      height: 12em;
      z-index: 1;
      animation: coin3anim 2.5s linear infinite alternate;
    }

    .app-coin5 {
      position: absolute;
      left: -2.5em;
      top: 35.2em;
      width: 16em;
      height: 12.5em;
      z-index: 1;
      animation: coin4anim 1.5s linear infinite alternate;
    }

    .app-coin5-2 {
      position: absolute;
      right: 57.6em;
      top: 30em;
      width: 16em;
      height: 12.5em;
      z-index: 1;
    }

    .app-coin6 {
      position: absolute;
      left: 5.7em;
      top: 17.6em;
      width: 10em;
      height: 12em;
      z-index: 1;
      animation: coin6anim 2.2s linear infinite alternate;
    }

    .app-coin6-2 {
      position: absolute;
      right: 73em;
      top: 7em;
      width: 10em;
      height: 12em;
      z-index: 1;
      animation: coin6anim 2s linear infinite alternate;
    }
  .app-decoration {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 13.2em;
    z-index: 1;
  }
  .win {
    &__container {
      position: absolute;
      right: 6em;
      top: 26.4em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    &__item {
      padding: 15px 45px;
      border: 2px dashed #03AC44;
      border-radius: 12px;
      background: rgba(0,0,0,0.7);
      font-size: 32px;
      font-weight: 700;
      color: white;
      display: flex;
      gap: 2px;
    }
  }
  .wheel__button {
    padding: 16px 28px;
    font-size: 16px;
    font-weight: 700;
    background-color: #00FF63;
    color: #fff;
    border: 0;
    border-radius: 8px;
    margin: 30px auto 0 auto;
    cursor: pointer;
    text-transform: uppercase;
    animation: elka 300ms linear infinite alternate;
    transition: background-color 1.5s ease;
    &:disabled {
      background-color: #1E2226;
      animation: none;
    }
  }
  .main {
    height: 100vh;
    min-height: 65em;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    &__girl1 {
      min-width: 42em;
      width: 42em;
      position: relative;
      bottom: -5px;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 3em;
        right: 3em;
        height: 33.1em;
        background: #00C74D;
        border-radius: 50%;
        filter: blur(135px);
        z-index: -1;
      }
    }
    &__girl2 {
      width: 46.5em;
    }
    .wheel-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
    }
    .wheel {
      position: relative;
      &__text {
        color: white;
        text-align: center;
        margin-bottom: 4em;
        &--bold {
          font-size: 48px;
          font-weight: 700;
        }
        &--normal {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
        }
        &--accent {
          padding: 4px 5px;
          margin: 0 5px;
          border-radius: 6px;
          background: #00C74D;
          color: white;
          font-weight: 700;
          display: flex;
          gap: 4px;
        }
      }
      &__bg {
        width: 36em;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 5em;
          left: 5em;
          bottom: 5em;
          right: 5em;
          background: #79FFAD;
          border-radius: 50%;
          filter: blur(135px);
          z-index: -1;
        }
        &--spins {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 7em;
          height: 7em;
          background: radial-gradient(#00FF63, #03AC44);
          box-shadow: 0px 2.19px 4.37px rgba(0, 0, 0, 0.35);
          border-radius: 50%;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        &--spins-text {
          font-size: 16px;
          font-weight: 700;
          color: white;
          text-align: center;
          text-transform: uppercase;
        }
        &--spins-count {
          font-size: 28px;
          line-height: 20px;
          font-weight: 700;
          color: white;
          text-align: center;
        }
      }
      &__arrow {
        position: absolute;
        width: 7.7em;
        top: 1px;
        left: 14.2em;
      }
      .noAnimation {
        animation: cachcach 700ms linear infinite alternate;
      }
      &__wheel {
        position: absolute;
        top: 3.4em;
        left: 2.3em;
        width: 31em;
        height: 31em;
        transform: rotate(-90deg);
        &--text {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          overflow: hidden;
          transform: rotate(15deg);
        }
        &--field {
          position: absolute;
          left: 50%;
          top: -50%;
          width: 100%;
          height: 100%;
          background: white;
          transform-origin: 0 100%;
          transition: background 0.5s ease;
        }
        &--field-text {
          position: absolute;
          margin-right: 20px;
          margin-bottom: -11px;
          font-size: 18px;
          font-weight: 700;
          color: #00C74D;
          transition: color 0.5s ease;
          text-transform: uppercase;
          display: flex;
          gap: 2px;
        }
        &--field-container:nth-child(odd) {
          .wheel__wheel--field {
            background: #00C74D;
          }
          .wheel__wheel--field-text-wrapper {
            .wheel__wheel--field-text {
              color: white;
            }
          }
        }
        .zero {
          .wheel__wheel--field-text-wrapper {
            .wheel__wheel--field-text {
              color: #A1BFAD;
            }
          }
        }
        .bonus {
          .wheel__wheel--field {
            background: linear-gradient(384deg, #00C74D 0%, #00FF63 35%);
          }
        }
        .max {
          .wheel__wheel--field {
            background: linear-gradient(206deg, #FFE600 85%, #00C74D 100%);
          }
        }
        .highlight {
          .wheel__wheel--field {
            background: #0A8238;
          }
          .wheel__wheel--field-text-wrapper {
            .wheel__wheel--field-text {
              color: #00FF63;
            }
          }
        }
        &--field-text-wrapper {
          position: absolute;
          left: 50%;
          top: 0;
          width: 50%;
          height: 50%;
          transform-origin: 0 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
        }
      }
      &__button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 70px;
      }
    }
  }
  @media screen and (max-width: 1250px) {
    .app-coin5, .app-coin5-2, .app-coin2 {
      display: none;
    }
  }
  @media screen and (max-width: 1150px) {
    .main__girl2, .app-coin4-2 {
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    .win {
      &__container {
        right: -10em;
        top: calc(50% - 11em);
      }
      &__item {
        padding: 5px 35px;
      }
    }
    .header {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .app-decoration {
      height: 7%;
    }
    .app-coin2-2, .app-coin3, .app-coin3-2, .app-coin4, .app-coin6-2 {
      display: none;
    }
    .app-coin5 {
      display: block;
      left: calc(50% - 18em);
      top: calc(50% + 7em);
    }
    .app-coin6 {
      left: calc(50% - 18em);
      top: calc(50% - 18em);
    }
    .app-coin1 {
      right: calc(50% - 17em);
      top: calc(50% - 10em);
    }
    .main__girl1 {
      position: absolute;
      z-index: -1;
      bottom: 27%;
      right: calc(50% - 15em);
      min-width: 30em;
      width: 30em;
      transition: bottom 0.5s ease, right 0.5s ease, min-width 0.5s ease, width 0.5s ease;
      &::before {
        display: none;
      }
    }
    .main__girl1--haveWin {
      bottom: calc(50% - 9em);
      right: calc(50% - 4.5em);
      min-width: 21.1em;
      width: 21.1em;
    }
    .main {
      .wheel-container {
        height: 100%;
      }
      .wheel {
        &__button-container {
          margin-bottom: 5em;
        }
        &__wheel {
          top: 3em;
          left: 2.2em;
          width: 25.5em;
          height: 25.5em;
          &--field-text {
            font-size: 14px;
            margin-right: 10px;
            margin-bottom: -8px;
          }
        }
        &__bg {
          width: 30em;
        }

        &__arrow {
          position: absolute;
          width: 6.4em;
          top: 1px;
          left: 11.8em;
        }
      }
    }
  }
}

@keyframes cachcach {
  0% {
    transform: rotate(-94deg);
  }
  100% {
    transform: rotate(-86deg);
  }
}

@keyframes elka {
  0% {
    background: #0A8238;
  }
  100% {
    background: #00FF63;
  }
}

@keyframes coin6anim {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}

@keyframes coin4anim {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes coin3anim {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes coinFall {
  0% {
    top: -150%;
  }
  100% {
    top: 150%;
  }
}

img {
  width: 100%;
  height: auto;
}

::-webkit-scrollbar-thumb {
  background-color: #484E55;
}

::-webkit-scrollbar-track {
  background-color: #1A1E23;
}

::-webkit-scrollbar {
  width: 5px;
}

* {
  box-sizing: border-box;
}